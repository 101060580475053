import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthorizedLayout from '@/layouts/AuthorizedLayout';
import BlankLayout from '@/layouts/BlankLayout';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout';
import ExportDailyReportLayout from '@/layouts/ExportDailyReportLayout';

// import AuthorizedLayoutTop from '@/layouts/AuthorizedLayoutTop';

import Login from '@/views/auth/login/Index';
import ForgotPassword from '@/views/auth/ForgotPassword';
import UpdatePassword from '@/views/auth/UpdatePassword';

import Asset from '@/views/asset/Index.vue';
import AssetDetail from '@/views/asset/Detail.vue';
import AssetAnalytics from '@/views/asset/Analytics.vue';
import AssetDataLog from '@/views/asset/DataLog.vue';
import MasterAssetType from '@/views/asset_type/Index';
import MasterBunkerLocation from '@/views/bunker_location/Index';
import Company from '@/views/company/Index.vue';
import MasterFuelType from '@/views/fuel_type/Index';
import CompanyDetail from '@/views/company/Detail.vue';
import Home from '@/views/home/Index.vue';
import Weather from '@/views/home/Weather.vue';
import Device from '@/views/device/Index.vue';
import DeviceDetail from '@/views/device/Detail.vue';
import Group from '@/views/group/Index';
import GroupDetail from '@/views/group/Detail';
import Report from '@/views/report/Index';
// import ReportView from '@/views/report/View.vue';
import Role from '@/views/role/Index';
import Permission from '@/views/permission/Index';
import RoleDetail from '@/views/role/Detail';
import User from '@/views/user/Index';
import UserLogin from '@/views/user_login/Index';
import UserDetail from '@/views/user/Detail';
import Export from '@/views/export/Index.vue';

// import HomeNew from '@/views/home/indexNew.vue';
// import EmsDashboard from '@/views/dashboard/EmsDashboard.vue';
import FmsDashboard from '@/views/dashboard/FmsDashboard.vue';
// import EmsReport from '@/views/report/EmsReport.vue';
import FmsReport from '@/views/report/FmsReport.vue';
import FmsDatalog from '@/views/report/FmsReportDatalog.vue';

import BunkerDashboard from '@/views/dashboard/BunkerDashboard.vue';
import BunkeringLog from '@/views/bunkering_log/Index';

import CompanyActiveSelection from '@/views/auth/CompanyActiveSelection';
import AppActiveSelection from '@/views/auth/AppActiveSelection';

import Widgets from '../views/Widgets';
import Forbidden from '../views/Forbidden';

import AssetType from '../views/AssetType';

import store from '../store';

import middleware from './middleware';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Mantraz',
    component: AuthorizedLayout,
    redirect: '/home',
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'weather',
        name: 'Weather',
        component: Weather,
      },
      {
        path: 'dashboard/fms',
        name: 'FmsDashboard',
        component: FmsDashboard,
      },
      {
        path: 'dashboard/bunker',
        name: 'BunkerDashboard',
        component: BunkerDashboard,
      },
      {
        path: 'report/fms',
        name: 'FmsReport',
        component: FmsReport,
      },
      {
        path: 'report/datalog/fms',
        name: 'FmsDatalog',
        component: FmsDatalog,
      },
      {
        path: 'bunkering-log',
        name: 'BunkeringLog',
        component: BunkeringLog,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
      },
      {
        path: 'asset/analytics/:id',
        name: 'AssetAnalytics',
        component: AssetAnalytics,
      },
      {
        path: 'asset/datalog/:id',
        name: 'AssetDataLog',
        component: AssetDataLog,
      },
      {
        path: 'asset/type/',
        name: 'AssetType',
        component: AssetType,
      },
      {
        path: '401',
        name: 'Forbidden',
        component: Forbidden,
      },
      {
        path: 'report',
        name: 'Report',
        component: Report,
      },
      // {
      //   path: 'report/:id/:type',
      //   name: 'ReportView',
      //   component: ReportView,
      // },
    ],
  },
  {
    path: '/preference',
    name: 'Preference',
    component: BlankLayout,
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'active-company',
        name: 'CompanyActiveSelection',
        component: CompanyActiveSelection,
      },
      {
        path: 'active-app',
        name: 'AppActiveSelection',
        component: AppActiveSelection,
      },
    ],
  },
  {
    path: '/master',
    name: 'Master',
    component: AuthorizedLayout,
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'company',
        name: 'Company',
        component: Company,
      },
      {
        path: 'group',
        name: 'Group',
        component: Group,
      },
      {
        path: 'asset',
        name: 'Asset',
        component: Asset,
      },
      {
        path: 'device',
        name: 'Device',
        component: Device,
      },
      {
        path: 'device/:id',
        name: 'AssetDevice',
        component: Device,
      },
      {
        path: 'device/detail/:id',
        name: 'DeviceDetail',
        component: DeviceDetail,
      },
      {
        path: 'group/detail/:id',
        name: 'GroupDetail',
        component: GroupDetail,
      },
      {
        path: 'company/detail/:id',
        name: 'CompanyDetail',
        component: CompanyDetail,
      },
      {
        path: 'asset/detail/:id',
        name: 'AssetDetail',
        component: AssetDetail,
      },
      {
        path: 'user',
        name: 'User',
        component: User,
      },
      {
        path: 'user_login',
        name: 'UserLogin',
        component: UserLogin,
      },
      {
        path: 'user/:id',
        name: 'UserDetail',
        component: UserDetail,
      },
      {
        path: 'role',
        name: 'Role',
        component: Role,
      },
      {
        path: 'role/:id',
        name: 'RoleDetail',
        component: RoleDetail,
      },
      {
        path: 'asset-type',
        name: 'MasterAssetType',
        component: MasterAssetType,
      },
      {
        path: 'fuel-type',
        name: 'MasterFuelType',
        component: MasterFuelType,
      },
      {
        path: 'bunker-location',
        name: 'MasterBunkerLocation',
        component: MasterBunkerLocation,
      },
      {
        path: 'permission',
        name: 'Permission',
        component: Permission,
      },
    ],
  },
  {
    path: '/auth',
    name: 'UnAuthorized',
    component: UnauthorizedLayout,
    // redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: 'update-password/:emailtoken/:token',
        name: 'UpdatePassword',
        component: UpdatePassword,
      },
      {
        path: 'export',
        name: 'Export',
        component: Export,
      },
    ],
  },
  {
    path: '/dailyreport',
    name: 'UnAuthorized',
    component: ExportDailyReportLayout,
    redirect: '/dailyreport/export',
    children: [
      {
        path: 'export',
        name: 'Export',
        component: Export,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
