<template>
  <div id="windy"></div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable new-cap */
/* eslint-disable no-void */

export default {
  name: 'WindyMap',
  props: {
    ships: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      trail: {},
      dotTrail: {},
      shipsLayerGroups: {},
      map: null,
      currentPopupPage: 1,
      totalPopupPages: 2,
      trailCheckboxStatus: {},
    };
  },
  mounted() {
    const link = document.querySelector(
      'link[href*="leaflet@1.4.0/dist/leaflet.css"]',
    );
    if (link) {
      link.parentNode.removeChild(link);
    }
    this.initMap();
  },
  methods: {
    initMap() {
      try {
        console.log('========> initMap <========');
        const map = L.map('windy').setView([0, 0], 2);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap',
        }).addTo(map);

        windyInit(
          {
            key: '4cUJStutpydIlHOe2tFPJV2au9khfCDP',
            lat: 0,
            lon: 0,
            zoom: 10,
            verbose: true,
            map,
          },
          (windyAPI) => {
            console.debug('windyAPi leaflet', windyAPI);
            const { map } = windyAPI;
            this.map = map;

            this.addShipsToMap();
            console.log('========> Windi Map initialized <========');
          },
        );
        console.log('========> initMap completed <========');
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    },

    addShipsToMap() {
      console.log('========> addShipsToMap <========');
      this.ships.forEach((ship) => {
        const shipLayerGroup = L.layerGroup().addTo(this.map);
        this.shipsLayerGroups[ship.massId] = shipLayerGroup;

        const marker = this.createShipMarker(ship);
        shipLayerGroup.addLayer(marker);

        marker
          .bindPopup(this.createPopupContent(ship))
          .on('popupopen', (popup) => {
            setInterval(() => {
              this.addEventListener(popup, ship);
            }, 1000);
          });
      });

      this.fitMapToBounds();
      console.log('========> addShipsToMap completed <========');
    },

    createShipMarker(ship) {
      console.log('========> createShipMarker <========');
      const marker = L.marker(ship.lastLatLng, {
        icon: ship.icon,
        rotationAngle: ship.lastGpsData.track || 0,
        rotationOrigin: '50% 50%',
      });

      const tooltipClass = ship.dataStatus && ship.lastGpsData ? 'ship-online' : 'ship-offline';
      marker.bindTooltip(
        `<div class="ship-label ${tooltipClass}">${ship.massName}</div>`,
        {
          permanent: true,
          offset: [20, 10],
        },
      ).openTooltip();

      console.log('========> createShipMarker completed <========');
      return marker;
    },

    fitMapToBounds() {
      console.log('========> fitMapToBounds <========');
      const group = new L.featureGroup(
        this.ships.map((ship) => L.marker(ship.lastLatLng)),
      );
      this.map.fitBounds(group.getBounds(), { padding: [100, 100] });
      console.log('========> fitMapToBounds completed <========');
    },

    addEventListener(popup, ship) {
      console.log('========> addEventListener <========');
      this.$nextTick(() => {
        this.setupButtonEvent(`report-${ship.massId}`, () => this.handleClickReport(ship.massId));
        this.setupButtonEvent(`dashboard-${ship.massId}`, () => this.handleClickDashboard(ship.massId));
        this.addPaginationButtonEvent(popup, ship);
        this.addTrailButtonEvent(popup, ship);
      });
      console.log('========> addEventListener completed <========');
    },

    setupButtonEvent(buttonId, callback) {
      console.log(`========> setupButtonEvent for ${buttonId} <========`);
      const button = document.getElementById(buttonId);
      if (button) {
        button.addEventListener('click', callback);
      }
      console.log(`========> setupButtonEvent for ${buttonId} completed <========`);
    },

    addTrailButtonEvent(popup, ship) {
      console.log('========> addTrailButtonEvent <========');
      this.$nextTick(() => {
        const trailSwitch = document.getElementById(`trail-switch-${ship.massId}`);
        if (trailSwitch) {
          trailSwitch.checked = this.trailCheckboxStatus[ship.massId] || false;
          trailSwitch.addEventListener('change', (event) => {
            this.toggleTrail(event.target.checked, ship);
            this.updatePopupContent(popup, ship);
          });
        }
      });
      console.log('========> addTrailButtonEvent completed <========');
    },

    toggleTrail(isChecked, ship) {
      console.log(`========> toggleTrail for ${ship.massId} <========`);
      const coordinates = ship.coordinates.filter(
        (x) => x[0] !== undefined && x[1] !== undefined,
      );
      this.trailCheckboxStatus[ship.massId] = isChecked;
      if (isChecked) {
        const trailLayer = L.polyline(coordinates, {
          color: ship.dataStatus ? '#28F385' : '#FF3B30',
        });
        this.shipsLayerGroups[ship.massId].addLayer(trailLayer);
        this.trail[ship.massId] = trailLayer;
        trailLayer.addTo(this.map);
        this.addDotTrailButtonEvent(ship);
      } else if (this.trail[ship.massId]) {
        this.map.removeLayer(this.trail[ship.massId]);
        this.shipsLayerGroups[ship.massId].removeLayer(this.trail[ship.massId]);
        delete this.trail[ship.massId];
      }
      console.log(`========> toggleTrail for ${ship.massId} completed <========`);
    },

    addDotTrailButtonEvent(ship) {
      console.log('========> addDotTrailButtonEvent <========');
      this.$nextTick(() => {
        const dotTrailSwitch = document.getElementById(
          `dot-trail-switch-${ship.massId}`,
        );
        if (dotTrailSwitch) {
          dotTrailSwitch.addEventListener('change', (event) => {
            this.toggleDotTrail(event.target.checked, ship);
          });
        }
      });
      console.log('========> addDotTrailButtonEvent completed <========');
    },

    toggleDotTrail(isChecked, ship) {
      console.log(`========> toggleDotTrail for ${ship.massId} <========`);
      if (isChecked) {
        const coordinatesData = ship.coordinatesData.filter(
          (x) => x.latLong[0] !== undefined && x.latLong[1] !== undefined,
        );
        this.dotTrail[ship.massId] = this.dotTrail[ship.massId] || [];
        coordinatesData.forEach((data) => {
          const marker = L.marker(data.latLong, {
            icon: ship.markerIcon,
            rotationAngle: data.heading || 0,
            rotationOrigin: '50% 50%',
          });
          const currentPage = 1;
          marker.bindPopup(this.createDotTrailPopupContent(ship, data, currentPage));
          marker.on('popupopen', () => {
            this.setupPaginationButtons(marker, ship, data, currentPage);
          });

          this.dotTrail[ship.massId].push(marker);
          this.shipsLayerGroups[ship.massId].addLayer(marker);
          marker.addTo(this.map);
        });
      } else {
        if (this.dotTrail[ship.massId]) {
          this.dotTrail[ship.massId].forEach((marker) => {
            this.map.removeLayer(marker);
            this.shipsLayerGroups[ship.massId].removeLayer(marker);
          });
        }

        delete this.dotTrail[ship.massId];
      }
      console.log(`========> toggleDotTrail for ${ship.massId} completed <========`);
    },

    addPaginationButtonEvent(popup, ship) {
      console.log('========> addPaginationButtonEvent <========');
      this.$nextTick(() => {
        this.setupButtonEvent(`previous-popup-button-${ship.massId}`, () => {
          this.previousPopupPage();
          this.updatePopupContent(popup, ship);
        });
        this.setupButtonEvent(`next-popup-button-${ship.massId}`, () => {
          this.nextPopupPage();
          this.updatePopupContent(popup, ship);
        });
        this.addDotTrailButtonEvent(ship);
      });
      console.log('========> addPaginationButtonEvent completed <========');
    },

    updatePopupContent(popup, ship) {
      console.log('========> updatePopupContent <========');
      const newPopup = this.createPopupContent(ship);
      popup.popup._contentNode.innerHTML = newPopup;
      this.addPaginationButtonEvent(popup, ship);
      this.addTrailButtonEvent(popup, ship);
      this.renderTrailSwitches(ship);
      console.log('========> updatePopupContent completed <========');
    },

    createPopupContent(ship) {
      console.log('========> createPopupContent <========');
      const content = `
        <div class="row pt-3">
          <div class="col-6 d-flex align-items-center">
            <h5>${ship.massName || '-'}</h5>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center pt-0">
            <h5 class="${ship.dataStatus ? 'status online' : 'status offline'}">
              ${ship.dataStatus ? 'online' : 'offline'}
            </h5>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <p style="margin: 0; font-size: 14px"><b>Last Data: </b> ${new Date(ship.lastGpsData?.timestamp * 1000 || 0).toLocaleString()}</p>
          </div>
        </div>
        <hr />
        <div class="row info-section pb-2">
          ${this.currentPopupPage === 1 ? this.renderFirstPageInfo(ship) : this.renderSecondPageInfo(ship)}
        </div>
        <hr />
        <div class="row pb-3">
          ${this.renderTrailSwitches(ship)}
        </div>
        <hr />
        <div class="row button-section">
          ${this.renderActionButtons(ship)}
        </div>
      `;
      console.log('========> createPopupContent completed <========');
      return content;
    },

    renderFirstPageInfo(ship) {
      console.log('========> renderFirstPageInfo <========');
      const content = `
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
          <p style="font-weight: 1000; color: #1e1e1e">${ship.location || '-'}</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
          <p style="font-weight: 1000; color: #1e1e1e">${ship.summary?.gps?.summary?.totalDistance ? (ship.summary.gps.summary.totalDistance / 1852).toFixed(2) : '-'} NM</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
          <p style="font-weight: 1000; color: #1e1e1e">${ship.summary?.gps?.summary?.averageSpeed ? (ship.summary.gps.summary.averageSpeed / 1.852).toFixed(2) : '-'} knot</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
          <p style="font-weight: 1000; color: #1e1e1e">AE ${ship.lastAeData?.find((ae) => ae.rpm > 0)?.no + 1 || '-'}</p>
        </div>
      `;
      console.log('========> renderFirstPageInfo completed <========');
      return content;
    },

    renderSecondPageInfo(ship) {
      console.log('========> renderSecondPageInfo <========');
      const content = `
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
          <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastRpmData?.find((rpm) => rpm.type === 'PORT')?.rpm || 0)}</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
          <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastRpmData?.find((rpm) => rpm.type === 'STARBOARD')?.rpm || 0)}</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
          <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastFmData?.find((fm) => fm.deviceId.split('-').pop() === '1')?.volumeFlowrate || 0)} L/h</p>
        </div>
        <div class="col-6" style="height: 50px">
          <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
          <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastFmData?.find((fm) => fm.deviceId.split('-').pop() === '3')?.volumeFlowrate || 0)} L/h</p>
        </div>
      `;
      console.log('========> renderSecondPageInfo completed <========');
      return content;
    },

    renderTrailSwitches(ship) {
      console.log('========> renderTrailSwitches <========');
      const isChecked = this.trailCheckboxStatus[ship.massId] || false;
      const content = `
        <div class="col-6" style="height: 15px">
          <div class="custom-control custom-switch mr-2">
            <input type="checkbox" class="custom-control-input" id="trail-switch-${ship.massId}" ${isChecked ? 'checked' : ''} />
            <label class="custom-control-label" for="trail-switch-${ship.massId}">Vessel Trail</label>
          </div>
          ${isChecked ? `
            <div class="custom-control custom-switch mr-2">
              <input type="checkbox" class="custom-control-input" id="dot-trail-switch-${ship.massId}" ${this.dotTrail[ship.massId]?.length > 0 ? 'checked' : ''} />
              <label class="custom-control-label" for="dot-trail-switch-${ship.massId}">Dot Trail</label>
            </div>
          ` : ''}
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button class="btn btn-sm btn-outline-mantraz mr-1" id="previous-popup-button-${ship.massId}" ${this.currentPopupPage === 1 ? 'disabled' : ''}>Previous</button>
          <button class="btn btn-sm btn-outline-mantraz" id="next-popup-button-${ship.massId}" ${this.currentPopupPage === this.totalPopupPages ? 'disabled' : ''}>Next</button>
        </div>
      `;
      console.log('========> renderTrailSwitches completed <========');
      return content;
    },

    renderActionButtons(ship) {
      console.log('========> renderActionButtons <========');
      const content = `
        <div class="col-6 my-1">
          <button class="btn btn-sm btn-block btn-mantraz tooltip-button" id="report-${ship.massId}">View Report</button>
        </div>
        <div class="col-6 my-1">
          <button class="btn btn-sm btn-block btn-outline-mantraz tooltip-button" id="dashboard-${ship.massId}">View Dashboard</button>
        </div>
      `;
      console.log('========> renderActionButtons completed <========');
      return content;
    },

    previousPopupPage() {
      console.log('========> previousPopupPage <========');
      if (this.currentPopupPage > 1) {
        this.currentPopupPage--;
      }
      console.log('========> previousPopupPage completed <========');
    },

    nextPopupPage() {
      console.log('========> nextPopupPage <========');
      if (this.currentPopupPage < this.totalPopupPages) {
        this.currentPopupPage++;
      }
      console.log('========> nextPopupPage completed <========');
    },

    createDotTrailPopupContent(ship, data, currentPage) {
      console.log('========> createDotTrailPopupContent <========');
      const location = '-';
      const totalCruise = data?.totalCruise?.toFixed(2) || '-';
      const avgSpeed = data?.avgSpeed?.toFixed(2) || '-';
      const currentAE = data?.currentAe || '-';
      const portRPM = data?.portRpm?.toFixed(2) || '-';
      const starboardRPM = data?.starboardRpm?.toFixed(2) || '-';
      const portInFlowrate = data?.portInFlowrate?.toFixed(2) || '-';
      const starboardInFlowrate = data?.starboardInFlowrate?.toFixed(2) || '-';
      let content = `
        <div class="row info-section pb-2">
      `;

      if (currentPage === 1) {
        content += `
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
            <p style="font-weight: 1000; color: #1e1e1e">${location}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
            <p style="font-weight: 1000; color: #1e1e1e">${totalCruise} NM</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
            <p style="font-weight: 1000; color: #1e1e1e">${avgSpeed} knot</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
            <p style="font-weight: 1000; color: #1e1e1e">AE ${currentAE}</p>
          </div>
        `;
      } else if (currentPage === 2) {
        content += `
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
            <p style="font-weight: 1000; color: #1e1e1e">${portRPM}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
            <p style="font-weight: 1000; color: #1e1e1e">${starboardRPM}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
            <p style="font-weight: 1000; color: #1e1e1e">${portInFlowrate} L/h</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
            <p style="font-weight: 1000; color: #1e1e1e">${starboardInFlowrate} L/h</p>
          </div>
        `;
      }

      content += `
        </div>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-sm btn-outline-mantraz" id="previous-popup-button-${ship.massId}" ${currentPage === 1 ? 'disabled' : ''}>Previous</button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-sm btn-outline-mantraz" id="next-popup-button-${ship.massId}" ${currentPage === 2 ? 'disabled' : ''}>Next</button>
          </div>
        </div>
      `;

      console.log('========> createDotTrailPopupContent completed <========');
      return content;
    },

    setupPaginationButtons(marker, ship, data, currentPage) {
      console.log('========> setupPaginationButtons <========');
      const previousButton = document.getElementById(`previous-popup-button-${ship.massId}`);
      const nextButton = document.getElementById(`next-popup-button-${ship.massId}`);

      if (previousButton) {
        previousButton.onclick = () => {
          if (currentPage > 1) {
            currentPage--;
            marker.setPopupContent(this.createDotTrailPopupContent(ship, data, currentPage));
            this.setupPaginationButtons(marker, ship, data, currentPage);
          }
        };
      }

      if (nextButton) {
        nextButton.onclick = () => {
          if (currentPage < 2) {
            currentPage++;
            marker.setPopupContent(this.createDotTrailPopupContent(ship, data, currentPage));
            this.setupPaginationButtons(marker, ship, data, currentPage);
          }
        };
      }
      console.log('========> setupPaginationButtons completed <========');
    },
  },
};
</script>

<style>
#windy #bottom #progress-bar {
  height: 60px !important;
}

#windy #bottom #progress-bar #playpause {
  top: 11px !important;
}

#windy .timecode {
  top: calc(-2.4em + 25px) !important;
}

#windy .progress-bar .progress-line {
  top: 15px !important;
}
</style>
