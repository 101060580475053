<template>
  <div
    id="map-section"
    style="height: calc(100vh - 70px); width: 100%"
    v-if="!isLoading"
  >
    <WindyMap :key="isWeatherMode" :ships="assets" />
  </div>
  <div class="text-center text-danger my-2" v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong>Loading...</strong>
  </div>
</template>

<script>
import WindyMap from '@/views/home/components/WindyMap';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    WindyMap,
  },
  data() {
    return {
      isLoading: true,
      isWeatherMode: false,
    };
  },
  computed: {
    ...mapState('asset', {
      assets: (state) => state.assets.filter((asset) => asset.lastGpsData),
    }),
  },
  methods: {
    async toggleWeatherMode() {
      this.isLoading = true;
      setTimeout(() => {
        this.isWeatherMode = !this.isWeatherMode;
        this.isLoading = false;
      }, 300); // Delay 300ms
    },
    async fetchData() {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      // this.$store.dispatch('asset/getAllLocationData');
      await this.$store.dispatch('asset/fetchAllLastSummary');
      this.isLoading = true;
    },
    zoomToShip(ship) {
      // eslint-disable-next-line no-undef
      this.center = L.latLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
  },
  async mounted() {
    await this.fetchData();
    this.isLoading = false;
  },
};
</script>
