import moment from 'moment';

const state = {
  date: {
    start: moment().startOf('day'),
    end: moment(),
  },
  currentMassId: null,
  interval: 'hour',
  rangeType: 'today',
};

const mutations = {
  SET_DATE(state, { start, end }) {
    state.date = { start, end };
  },
  SET_INTERVAL(state, interval) {
    state.interval = interval;
  },
  SET_CURRENT_MASS_ID(state, id) {
    state.currentMassId = id;
  },
  SET_RANGE_TYPE(state, rangeType) {
    state.rangeType = rangeType;
  },
};

const actions = {
  setDate({ commit }, { start, end }) {
    commit('SET_DATE', { start, end });
  },
  setCurrentMassId({ commit }, id) {
    commit('SET_CURRENT_MASS_ID', id);
  },
  setInterval({ commit }, interval) {
    commit('SET_INTERVAL', interval);
  },
  setRangeType({ commit }, rangeType) {
    console.debug('rangeType', rangeType);
    switch (rangeType) {
      case 'today':
        commit('SET_DATE', { start: moment().startOf('day'), end: moment() });
        break;
      case 'yesterday':
        commit('SET_DATE', { start: moment().subtract(1, 'day').startOf('day'), end: moment().subtract(1, 'day').endOf('day') });
        break;
      case 'thisWeek':
        commit('SET_DATE', { start: moment().startOf('week'), end: moment() });
        break;
      case 'lastWeek':
        commit('SET_DATE', { start: moment().subtract(1, 'week').startOf('week'), end: moment().subtract(1, 'week').endOf('week') });
        break;
      case 'thisMonth':
        commit('SET_DATE', { start: moment().startOf('month'), end: moment() });
        break;
      case 'lastMonth':
        commit('SET_DATE', { start: moment().subtract(1, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') });
        break;
      default:
        break;
    }

    commit('SET_RANGE_TYPE', rangeType);
  },
};

const getters = {
  date: (state) => state.date,
  currentMassId: (state) => state.currentMassId,
  interval: (state) => state.interval,
  rangeType: (state) => state.rangeType,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
