<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Create New Bunker Log</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">

        <!-- Start Date & Time -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgStartDatetime" class="d-block form-label text-left">
            Bunker Start Date & Time:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <v-date-picker v-model="formData.bklgStartDatetime" mode="datetime" :masks="{ input: 'DD-MM-YYYY HH:mm' }" is24hr>
                <template #default="{ inputValue, inputEvents, isDragging }">
                  <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div class="position-relative flex-grow-1">
                      <input
                        class="form-control bg-light border rounded"
                        style="width: 320px;"
                        :class="isDragging ? 'text-muted' : 'text-dark'"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <b-form-invalid-feedback v-if="errors.bklgStartDatetime">
                {{ Object.values(errors.bklgStartDatetime) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Start Date & Time -->

        <!-- End Date & Time -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgEndDatetime" class="d-block form-label text-left">
            Bunker End Date & Time:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <v-date-picker v-model="formData.bklgEndDatetime" mode="datetime" :masks="{ input: 'DD-MM-YYYY HH:mm' }" is24hr>
                <template #default="{ inputValue, inputEvents, isDragging }">
                  <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div class="position-relative flex-grow-1">
                      <input
                        class="form-control bg-light border rounded"
                        style="width: 320px;"
                        :class="isDragging ? 'text-muted' : 'text-dark'"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <b-form-invalid-feedback v-if="errors.bklgEndDatetime">
                {{ Object.values(errors.bklgEndDatetime) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./End Date & Time -->

        <!-- Start Sounding -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgStartSounding" class="d-block form-label text-left">
            Start Sounding In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="number"
                step="0.01"
                id="bklgStartSounding"
                placeholder="Bunker End Sounding In KL"
                v-model="formData.bklgStartSounding"
                :class="errors.bklgStartSounding ? 'is-invalid' : ''"
              >
              <b-form-invalid-feedback v-if="errors.bklgStartSounding">
                {{ Object.values(errors.bklgStartSounding) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Start Sounding -->

        <!-- End Sounding -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgEndSounding" class="d-block form-label text-left">
            End Sounding In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="number"
                step="0.01"
                id="bklgEndSounding"
                placeholder="Bunker End Sounding In KL"
                v-model="formData.bklgEndSounding"
                :class="errors.bklgEndSounding ? 'is-invalid' : ''"
              >
              <b-form-invalid-feedback v-if="errors.bklgEndSounding">
                {{ Object.values(errors.bklgEndSounding) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./End Sounding -->

        <!-- Target Bunker -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgTargetBunker" class="d-block form-label text-left">
            Target Bunker In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="number"
                step="0.01"
                id="bklgTargetBunker"
                placeholder="Bunker Target Bunker In KL"
                v-model="formData.bklgTargetBunker"
                :class="errors.bklgTargetBunker ? 'is-invalid' : ''"
              >
              <b-form-invalid-feedback v-if="errors.bklgTargetBunker">
                {{ Object.values(errors.bklgTargetBunker) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Target Bunker -->

        <!-- Assets -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMassId" class="d-block form-label text-left">
            Asset :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select
                class="form-control"
                id="bklgMassId"
                placeholder="Select Asset"
                v-model="formData.bklgMassId"
                :options="assets"
                :class="errors.bklgMassId ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback v-if="errors.bklgMassId">
                {{ Object.values(errors.bklgMassId) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Assets -->

        <!-- Fuel Type -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMftyId" class="d-block form-label text-left">
            Fuel Type :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select
                v-if="!isAddingFuelType"
                class="form-control"
                id="bklgMftyId"
                placeholder="Select Fuel Type"
                v-model="formData.bklgMftyId"
                :options="fuelTypes"
                :class="errors.bklgMftyId ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback v-if="errors.bklgMftyId">
                {{ Object.values(errors.bklgMftyId) }}
              </b-form-invalid-feedback>
              <b-button v-if="!isAddingFuelType" @click="isAddingFuelType = true" variant="outline-primary" size="sm" class="ml-2">
                Add Fuel Type
              </b-button>
              <b-form-input
                v-else
                v-model="newFuelTypeName"
                placeholder="Enter new fuel type"
                class="ml-2"
              />
              <b-button v-if="isAddingFuelType" @click="addFuelType" variant="primary" size="sm" class="ml-2">
                Save
              </b-button>
              <b-button v-if="isAddingFuelType" @click="isAddingFuelType = false" variant="outline-danger" size="sm" class="ml-2">
                Cancel
              </b-button>
            </div>
          </div>
        </div>
        <!-- ./Fuel Type -->

        <!-- Bunker Location -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMbloId" class="d-block form-label text-left">
            Bunker Location :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select
                v-if="!isAddingBunkerLocation"
                class="form-control"
                id="bklgMbloId"
                placeholder="Select Bunker Location"
                v-model="formData.bklgMbloId"
                :options="bunkerLocations"
                :class="errors.bklgMbloId ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback v-if="errors.bklgMbloId">
                {{ Object.values(errors.bklgMbloId) }}
              </b-form-invalid-feedback>
              <b-button v-if="!isAddingBunkerLocation" @click="isAddingBunkerLocation = true" variant="outline-primary" size="sm" class="ml-2">
                Add Bunker Location
              </b-button>
              <b-form-input
                v-else
                v-model="newBunkerLocationName"
                placeholder="Enter new bunker location"
                class="ml-2"
              />
              <b-button v-if="isAddingBunkerLocation" @click="addBunkerLocation" variant="primary" size="sm" class="ml-2">
                Save
              </b-button>
              <b-button v-if="isAddingBunkerLocation" @click="isAddingBunkerLocation = false" variant="outline-danger" size="sm" class="ml-2">
                Cancel
              </b-button>
            </div>
          </div>
        </div>
        <!-- ./Bunker Location -->
      </div>

      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import AssetSvc from '@/services/AssetSvc';
import FuelTypeSvc from '@/services/FuelTypeSvc';
import BunkerLocation from '@/services/BunkerLocationSvc';

export default {
  name: 'BunkerBunkeringLogForm',
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
      assets: [],
      fuelTypes: [],
      bunkerLocations: [],
      formData: {},
      newFuelTypeName: '',
      newBunkerLocationName: '',
      isAddingFuelType: false,
      isAddingBunkerLocation: false,
    };
  },
  async mounted() {
    // Fetch assets, fuel types, and bunker locations
    await this.fetchData();
    // Initialize form data
    this.initializeFormData();
  },
  methods: {
    async fetchData() {
      const assetResponse = await AssetSvc.getAssets({ params: { max: 1000 } });
      const fuelTypesResponse = await FuelTypeSvc.getFuelType({ max: 1000 });
      const bunkerLocationResponse = await BunkerLocation.getBunkerLocation({ max: 1000 });

      this.assets = assetResponse.data.data.map((asset) => ({ value: asset.massId, text: asset.massName }));
      this.fuelTypes = fuelTypesResponse.data.map((fuelType) => ({ value: fuelType.mftyId, text: fuelType.mftyName }));
      this.bunkerLocations = bunkerLocationResponse.data.map((bunkerLocation) => ({ value: bunkerLocation.mbloId, text: bunkerLocation.mbloName }));
    },
    initializeFormData() {
      const currentOffset = moment().utcOffset();
      if (this.data.bklgId) {
        this.formData = {
          ...this.data,
          bklgStartDatetime: moment(this.data?.bklgStartDatetime).add(currentOffset, 'minutes').toDate(),
          bklgEndDatetime: moment(this.data?.bklgEndDatetime).add(currentOffset, 'minutes').toDate(),
          bklgStartSounding: this.data?.bklgStartSounding * 0.001,
          bklgEndSounding: this.data?.bklgEndSounding * 0.001,
          bklgTargetBunker: this.data?.bklgTargetBunker * 0.001,
        };
      } else {
        this.formData = {
          bklgStartDatetime: moment().toDate(),
          bklgEndDatetime: moment().toDate(),
          bklgStartSounding: 0.00,
          bklgEndSounding: 0.00,
          bklgTargetBunker: 0.00,
        };
      }
    },
    async addFuelType() {
      const existingFuelType = this.fuelTypes.find((fuelType) => fuelType.text.toLowerCase() === this.newFuelTypeName.toLowerCase());
      if (existingFuelType) {
        this.formData.bklgMftyId = existingFuelType.value;
        this.newFuelTypeName = '';
        this.isAddingFuelType = false;
        await this.fetchData();
        return;
      }

      const newFuelType = { mftyName: this.newFuelTypeName };
      const createFuelTypeResponse = await FuelTypeSvc.createFuelType(newFuelType);
      const fuelTypeId = createFuelTypeResponse?.id;
      this.formData.bklgMftyId = fuelTypeId;
      this.newFuelTypeName = '';
      this.isAddingFuelType = false;
      await this.fetchData();
    },
    async addBunkerLocation() {
      const existingBunkerLocation = this.bunkerLocations.find((bunkerLocation) => bunkerLocation.text.toLowerCase() === this.newBunkerLocationName.toLowerCase());
      if (existingBunkerLocation) {
        this.formData.bklgMbloId = existingBunkerLocation.value;
        this.newBunkerLocationName = '';
        this.isAddingBunkerLocation = false;
        await this.fetchData();
        return;
      }

      const newBunkerLocation = { mbloName: this.newBunkerLocationName };
      const createBunkerLocationResponse = await BunkerLocation.createBunkerLocation(newBunkerLocation);
      const bunkerLocationId = createBunkerLocationResponse?.id;
      this.formData.bklgMbloId = bunkerLocationId;
      this.newBunkerLocationName = '';
      this.isAddingBunkerLocation = false;
      await this.fetchData();
    },
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const formData = { ...this.formData };
      this.$emit('onSubmitClicked', formData);
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
