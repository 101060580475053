<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!formData.userCreatedTime">Add New Permission</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">
        <div role="group" class="form-group col-md-12">
          <label for="prmsName" class="d-block form-label text-left">
            Permission Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="text"
                id="prmsName"
                placeholder="Permission Name"
                v-model="formData.prmsName"
                :class="errors.prmsName ? 'is-invalid' : ''"
              >
              <b-form-invalid-feedback v-if="errors.prmsName">
                {{ Object.values(errors.prmsName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>

        <div role="group" class="form-group col-md-12">
          <label class="d-block form-label text-left">
            Permission Operation:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-checkbox-group v-model="formData.prmsOperations" class="d-block">
                <b-form-checkbox value="create">Create</b-form-checkbox>
                <b-form-checkbox value="read">Read</b-form-checkbox>
                <b-form-checkbox value="update">Update</b-form-checkbox>
                <b-form-checkbox value="delete">Delete</b-form-checkbox>
              </b-form-checkbox-group>
              <b-form-invalid-feedback v-if="errors.prmsOperations">
                {{ Object.values(errors.prmsOperations) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FuelTypeForm',
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
      formData: {},
    };
  },
  mounted() {
    this.formData = { ...this.data, prmsOperations: JSON.parse(this.data.prmsOperations) };
  },
  methods: {

    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const permissions = { ...this.formData };
      this.$emit('onSubmitClicked', permissions);
    },
  },
};
</script>

<style scoped>

</style>
