<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Add New Asset Type</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="userId" class="d-block form-label text-left">
            Asset Type Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                class="form-control"
                type="text"
                id="matyName"
                placeholder="Asset Type Name"
                v-model="data.matyName"
                :class="errors.matyName ? 'is-invalid' : ''"
              >
              <b-form-invalid-feedback v-if="errors.userId">
                {{ Object.values(errors.userId) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserForm',
  props: ['data', 'errors', 'isSubmit'],
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
  },
};
</script>

<style scoped>

</style>
