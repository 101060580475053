<template>
  <div class="login-container">
    <div class="login-form">
      <b-row class="mb-3" align-v="center">
        <b-col md="12" class="text-center">
          <b-img :src="mantrazLogo" alt="Mantraz Logo" class="login-logo"></b-img>
        </b-col>
      </b-row>
      <div class="login-form-card">
        <h3 class="mb-3 text-center">Update Password</h3>
        <p class="text-center mx-auto login-subtitle">Enter your new password to update your account.</p>
        <div class="form-container">
          <update-password-form @onSuccess="onSuccess" @onFailure="onFailure" />
        </div>
      </div>
    </div>
    <div class="login-image">
      <div class="logo-container">
        <img :src="mantrazLogo" alt="Mantraz Logo" />
      </div>
      <img :src="backgroundImageURL" alt="Background Image" class="background-image">
    </div>

    <!-- Modal -->
    <b-modal id="updatePasswordModal" title="Notification" ok-title="OK" @hide="resetModal">
      <p>{{ modalText }}</p>
    </b-modal>
  </div>
</template>

<script>
import gmailIcon from '@/assets/images/gmail.png';
import UpdatePasswordForm from '@/components/auth/UpdatePasswordForm';
import mantrazImage from '@/assets/images/Mantraz.png';
import backgroundImage from '@/assets/images/background.jpg';

export default {
  name: 'UpdatePassword',
  components: {
    UpdatePasswordForm,
  },
  data() {
    return {
      displayLoginForm: process.env.VUE_APP_ENABLE_FORM_LOGIN,
      gmailIconURL: gmailIcon,
      mantrazLogo: mantrazImage,
      backgroundImageURL: backgroundImage,
      modalText: 'You will be redirectet to login page',
    };
  },
  mounted() {
  },
  methods: {
    onSuccess() {
      this.showModal('Password updated successfully. You will be redirectet to login page.');

      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 5000);
    },
    async onFailure(err) {
      console.log('login failure', err);
    },
    showModal(txt) {
      this.modalText = txt;
      this.$bvModal.show('updatePasswordModal'); // Menampilkan modal
    },
    resetModal() {
      this.$bvModal.hide('updatePasswordModal'); // Menampilkan modal
    },
  },
};
</script>

<style scoped>
.login-form-card {
  padding: 35px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  max-width: 400px;
  margin: 0 auto;
}

.login-subtitle {
  font-size: 14px;
  color: #8d8d8d;
}

.login-logo {
  width: 200px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 0;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.login-container {
  min-height: 100vh;
  display: flex;
}

.login-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-image {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.login-image .logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.login-image .logo-container img {
  width: 300px;
  height: auto;
}

.login-image .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}

/* Media Query untuk Mobile */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-image {
    display: none;
  }

  .login-form {
    border-right: none;
    padding: 20px;
    max-height: 100vh;
  }
}
</style>
