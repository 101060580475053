import axios from 'axios';

/**
 * Service untuk mengelola Fuel Type
 * @namespace FuelTypeSvc
 */
export default {
  /**
   * Mendapatkan semua data Fuel Type
   * @function getFuelType
   * @return {Promise} Promise yang berisi response dari server
   */
  getFuelType: async (params) => {
    const { data } = await axios.get('/master/fuel_type', {
      params: {
        ...params,
      },
    });
    return data;
  },
  /**
   * Mendapatkan data Fuel Type berdasarkan ID
   * @function getFuelTypeById
   * @param {number} id - ID dari Fuel Type yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getFuelTypeById: async (id) => {
    const { data } = await axios.get(`/master/fuel_type/${id}`);
    return data;
  },
  /**
   * Membuat data Fuel Type baru
   * @function createFuelType
   * @param {object} data - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createFuelType: async (fuelTypeData) => {
    const { data } = await axios.post('/master/fuel_type', fuelTypeData);
    return data;
  },
  /**
   * Mengupdate data Fuel Type yang sudah ada
   * @function updateFuelType
   * @param {number} id - ID dari Fuel Type yang ingin di update
   * @param {object} data - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateFuelType: async (id, fuelTypeData) => {
    const { data } = await axios.put(`/master/fuel_type/${id}`, fuelTypeData);
    return data;
  },
  /**
   * Menghapus data Fuel Type yang sudah ada
   * @function deleteFuelType
   * @param {number} id - ID dari Fuel Type yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteFuelType: async (id) => {
    const { data } = await axios.delete(`/master/fuel_type/${id}`);
    return data;
  },
};
