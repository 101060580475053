<template>
  <div>
    <button-options
      :access="access"
      :hideAdd="true"
      :hideDelete="false"
      :hideDetail="true"
      :hideEdit="false"
      @onDeleteClicked="onDeleteClicked"
      @onEditClicked="onEditClicked"
      @onViewClicked="onViewClicked"/>
  </div>
</template>

<script>
import ButtonOptions from '@/components/directives/button-options';

export default {
  name: 'BunkerBunkeringLogEdit',
  components: { ButtonOptions },
  props: ['access', 'dataToEdit'],
  computed: {
    data() {
      return { ...this.dataToEdit };
    },
  },
  methods: {
    onDeleteClicked() {
      this.$emit('onDeleteClicked', this.data);
    },
    onEditClicked() {
      this.$emit('onEditClicked', this.data);
    },
    onViewClicked() {
      this.$emit('onViewClicked', this.data);
    },
  },
};
</script>

<style scoped>

</style>
