<template>
    <div>
        <!-- <pre v-if="assetObject[activeAssetId]">{{assetObject[activeAssetId].lastData}}</pre> -->
        <div class="mt-4 mb-2">
            <b-table-simple hover small responsive id="gpsTable">
                <b-thead head-variant="dark" v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                    <b-tr>
                        <b-td style="background-color: #002a64 !important; color: white;"></b-td>
                        <b-th style="background-color: lightgray !important; color: black;">Position</b-th>
                        <b-th style="background-color: lightgray !important; color: black;">Time</b-th>
                        <b-th style="background-color: lightgray !important; color: black;">Speed</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                    <b-tr v-if="assetObject[activeAssetId].firstData.gps">
                        <b-th style="background-color: #002a64 !important; color: white;">Start</b-th>
                        <b-td>{{assetObject[activeAssetId].firstData.gps.latitude | latToDMS}},
                            {{assetObject[activeAssetId].firstData.gps.longitude | longToDMS}}</b-td>
                        <b-td>{{assetObject[activeAssetId].firstData.gps.timestamp | toLocalDate}}</b-td>
                        <b-th>{{assetObject[activeAssetId].summaries.gps.calculatedSpeed.toFixed(2)}} Knot</b-th>
                    </b-tr>
                    <b-tr v-if="assetObject[activeAssetId].lastData.gps">
                        <b-th style="background-color: #002a64 !important; color: white;">Last</b-th>
                        <b-td>{{assetObject[activeAssetId].lastData.gps.latitude | latToDMS}},
                            {{assetObject[activeAssetId].lastData.gps.longitude | longToDMS}}</b-td>
                        <b-td>{{assetObject[activeAssetId].lastData.gps.timestamp | toLocalDate}}</b-td>
                        <b-th >{{assetObject[activeAssetId].summaries.gps.calculatedSpeedKMh.toFixed(2)}} Knot</b-th>
                    </b-tr>
                </b-tbody>
                <!-- <b-tfoot v-if="assetObject[activeAssetId].start && assetObject[activeAssetId].firstData">
                    <b-tr>
                        <b-th variant="primary">Total Distance</b-th>
                        <b-th colspan="3" variant="primary">{{assetObject[activeAssetId].summaries.gps.distance |
                            toNM}} Nautical Mile</b-th>
                    </b-tr>
                </b-tfoot>
                <b-tfoot v-else>
                    <b-th colspan="4" variant="primary" class="text-center">No Data</b-th>
                </b-tfoot> -->
            </b-table-simple>
        </div>
        <div style="display: grid; align-items: center; gap: 10px; grid-template-columns: 1fr 1fr;">
            <img style="height: 300px;" id="map-image" :src="mapLeft"/>
            <img style="height: 300px;" id="map-image" :src="mapRight"/>
            <!-- <Map v-if="assetObject[activeAssetId].firstData&&assetObject[activeAssetId].firstData.gps&&assetObject[activeAssetId].lastData.gps" :center="[0 , 0]" :data="assetObject" :showSummary="false" :disableScrollMap="'disable'" :hideMapSetting="true" @mapGenerated="mapGenerated" style="height: 300px !important; width: 100%;" :isDailyReport="true" :isZoomIn="true"/>
            <Map v-if="assetObject[activeAssetId].firstData&&assetObject[activeAssetId].firstData.gps&&assetObject[activeAssetId].lastData.gps" :center="[0 , 0]" :data="assetObject" :showSummary="false" :disableScrollMap="'disable'" :hideMapSetting="true" @mapGenerated="mapGenerated" style="height: 300px !important; width: 100%;" :isDailyReport="true" :isZoomOut="true"/> -->
        </div>
        <!-- <Map
                    :center="[0 , 0]"
                    :data="assetObject"
                    :showSummary="true"
                    @mapUpdated="mapUpdated"
                    class="row h-100"
                    style="position: sticky;"/> -->
    </div>
</template>

<script>
// import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import axios from 'axios';
// import Map from '@/components/directives/iq-map';

export default {
  name: 'VesselTracking',
  components: {
    // Map,
  },
  props: ['assetObject', 'isLoading', 'activeAssetId'],
  data() {
    return {
      mapLeft: '',
      mapRight: '',
    };
  },
  async mounted() {
    await this.renderMapImage();
    console.log('gps mounted assetObject>>>', this.assetObject, this.activeAssetId);
  },
  methods: {
    async renderMapImage() {
      this.mapLeft = await this.generateImage();
      this.mapRight = await this.generateImage(true);
    },
    async generateImage(withoutPath) {
      try {
        const zoom = withoutPath ? 6 : 11;
        const lat = this.assetObject[this.activeAssetId].lastData.gps.latitude;
        const lng = this.assetObject[this.activeAssetId].lastData.gps.longitude;
        const currPath = Object.values(this.assetObject[this.activeAssetId].datas).map((item) => [item.gps.latitude, item.gps.longitude]).slice(0, 50);
        console.log('currPath', currPath);
        const path = `color:0xff0000ff|weight:5|${currPath.join('|')}`;
        const constructUrl = withoutPath ? `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=535x300&markers=color:red%7C${lat},${lng}&maptype=satellite&key=${process.env.VUE_APP_GMAPS_API_KEY}` : `https://maps.googleapis.com/maps/api/staticmap?size=535x300&markers=color:red%7C${lat},${lng}&path=${path}&maptype=satellite&key=${process.env.VUE_APP_GMAPS_API_KEY}&sensor=false`;
        const res = await axios({
          method: 'post',
          url: 'https://beta.ramus.id/api-signature/generate-signature',
          data: {
            url: constructUrl,
            privateKey: process.env.VUE_APP_GMAPS_PRIVATE_KEY,
          },
        });
        return res.data.result;
      } catch (error) {
        console.log('cuan error 2', error);
      }
    },
    // mapGenerated(mapObject) {
    //   console.info('mapGenerated');
    //   this.screenShoter = new SimpleMapScreenshoter().addTo(mapObject);
    //   // setTimeout(() => {
    //   //   sms.takeScreen('image', {
    //   //     mimeType: 'image/png',
    //   //   }).then((image) => {
    //   //     this.mapShot = image;
    //   //     // console.log('IMAGE: ', image);
    //   //   }).catch((e) => {
    //   //     console.error(e);
    //   //   });
    //   // }, 2000);
    //   // console.log('MAP: ', sms);
    // },
  },
};
</script>

<style scoped>
#gpsTable td {
    border: 1px solid black !important;
}
#gpsTable th {
    border: 1px solid black !important;
}
</style>
