import axios from 'axios';

/**
 * Service untuk mengelola Bunkering Log
 * @namespace BunkeringLogSvc
 */
export default {
  /**
   * Mendapatkan semua data Bunkering Log
   * @function getBunkeringLog
   * @return {Promise} Promise yang berisi response dari server
   */
  getBunkeringLog: async (params) => {
    const { data } = await axios.get('/bunker/bunkering_log', {
      params: {
        ...params,
      },
    });
    return data;
  },
  /**
   * Mendapatkan data Bunkering Log berdasarkan periode
   * @function getBunkeringLogByPeriod
   * @param {number} unixStart - Unix timestamp mulai periode
   * @param {number} unixEnd - Unix timestamp akhir periode
   * @return {Promise} Promise yang berisi response dari server
   */
  getBunkeringLogByPeriod: async (unixStart, unixEnd, params) => {
    const { data } = await axios.get(`/bunker/bunkering_log/period/${unixStart}/${unixEnd}`, { params: { ...params } });
    return data;
  },
  /**
   * Mendapatkan data Bunkering Log berdasarkan ID
   * @function getBunkeringLogById
   * @param {number} id - ID dari Bunkering Log yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getBunkeringLogById: async (id) => {
    const { data } = await axios.get(`/bunker/bunkering_log/${id}`);
    return data;
  },
  /**
   * Membuat data Bunkering Log baru
   * @function createBunkeringLog
   * @param {object} data - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createBunkeringLog: async (bunkeringLogData) => {
    const { data } = await axios.post('/bunker/bunkering_log', bunkeringLogData);
    return data;
  },
  /**
   * Mengupdate data Bunkering Log yang sudah ada
   * @function updateBunkeringLog
   * @param {number} id - ID dari Bunkering Log yang ingin di update
   * @param {object} data - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateBunkeringLog: async (id, bunkeringLogData) => {
    const { data } = await axios.put(`/bunker/bunkering_log/${id}`, bunkeringLogData);
    return data;
  },
  /**
   * Menghapus data Bunkering Log yang sudah ada
   * @function deleteBunkeringLog
   * @param {number} id - ID dari Bunkering Log yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteBunkeringLog: async (id) => {
    const { data } = await axios.delete(`/bunker/bunkering_log/${id}`);
    return data;
  },
};
