<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Bunker Location</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_bunker_location'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <bunker-location-list :access="'master_bunker_location'" :data="data" :loading="isLoading" :pagination="pagination"
              @onActionDelete="openDelete" @onActionEdit="openEdit" @onActionView="openDetail"
              @onPageChanged="onPageChanged" @update="onUpdated" />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <bunker-location-form :data="currentBunkerLocation" :errors="errors" :isSubmit="isSubmit" @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import BunkerLocationSvc from '@/services/BunkerLocationSvc';
import BunkerLocationList from '@/components/bunker_location/List';
import BunkerLocationForm from '@/components/bunker_location/Form';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'MasterBunkerLocation',
  components: {
    DeleteItem, BunkerLocationForm, BunkerLocationList, ButtonOptions,
  },
  data() {
    return {
      currentBunkerLocation: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteBunkerLocationResponse = await BunkerLocationSvc.deleteBunkerLocation(data.mbloId);
        if (deleteBunkerLocationResponse.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteBunkerLocationResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;
        const bunkerLocationResponse = await BunkerLocationSvc.getBunkerLocation({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });

        const bunkerLocationData = bunkerLocationResponse.data;

        this.data = bunkerLocationData;

        this.pagination.totalSize = bunkerLocationResponse.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something Wrong');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentBunkerLocation = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Bunker Location Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      try {
        this.errors = {};
        this.isSubmit = true;

        const bunkerLocationData = {
          mbloName: form.mbloName,
        };

        if (!form.mbloId) { // jika tidak ada mbloId maka create
          const createBunkerLocationResponse = await BunkerLocationSvc.createBunkerLocation(bunkerLocationData);

          if (createBunkerLocationResponse.status.error === 0) {
            await this.getData('Success');
            this.currentBunkerLocation = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada mbloId maka update
          const updateBunkerLocationResponse = await BunkerLocationSvc.updateBunkerLocation(form.mbloId, bunkerLocationData);
          console.debug('updateBunkerLocationResponse : ', updateBunkerLocationResponse);

          if (updateBunkerLocationResponse.status.error === 0) {
            await this.getData('Success');
            this.currentBunkerLocation = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error When Submiting Form');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentBunkerLocation = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentBunkerLocation = { ...this._.find(this.data, (v) => v.mbloId === data.mbloId) };
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
