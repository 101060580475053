import axios from 'axios';

/**
 * Service untuk mengelola Asset Type
 * @namespace AssetTypeSvc
 */
export default {
  /**
   * Mendapatkan semua data Asset Type
   * @function getAssetType
   * @return {Promise} Promise yang berisi response dari server
   */
  getAssetType: async (params) => {
    const { data } = await axios.get('/master/asset_type', {
      params: {
        ...params,
      },
    });
    return data;
  },
  /**
   * Mendapatkan data Asset Type berdasarkan ID
   * @function getAssetTypeById
   * @param {number} id - ID dari Asset Type yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getAssetTypeById: async (id) => {
    const { data } = await axios.get(`/master/asset_type/${id}`);
    return data;
  },
  /**
   * Membuat data Asset Type baru
   * @function createAssetType
   * @param {object} data - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createAssetType: async (assetTypeData) => {
    const { data } = await axios.post('/master/asset_type', assetTypeData);
    return data;
  },
  /**
   * Mengupdate data Asset Type yang sudah ada
   * @function updateAssetType
   * @param {number} id - ID dari Asset Type yang ingin di update
   * @param {object} data - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateAssetType: async (id, assetTypeData) => {
    const { data } = await axios.put(`/master/asset_type/${id}`, assetTypeData);
    return data;
  },
  /**
   * Menghapus data Asset Type yang sudah ada
   * @function deleteAssetType
   * @param {number} id - ID dari Asset Type yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteAssetType: async (id) => {
    const { data } = await axios.delete(`/master/asset_type/${id}`);
    return data;
  },
};
