<template>
  <b-container style="padding-top: 20px">
    <div class="iq-card" style="max-height: 50%; max-width: 50%; margin: 0 auto;">
      <div class="iq-card-header">
        <div class="iq-header-title w-100">
          <div class="py-3 text-center">
            <h4 class="card-title mb-2">Select Company</h4>
            <h6 class="text-center">Select your company to continue.</h6>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="select-group-company-wrapper">
          <b-card v-for="availableGroup in availableGroups" :key="availableGroup.grcpId" no-body>
            <b-card-header header-tag="header" role="tab">
              <b-button
                class="select-group-button"
                block
                v-b-toggle="'select-group-' + availableGroup.grcpId"
                v-on:click="onGroupSelected(availableGroup)"
              >{{ availableGroup.grcpName }}</b-button>
            </b-card-header>
            <b-collapse
              :id="'select-group-' + availableGroup.grcpId"
              accordion="select-group-company-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-button
                v-for="availableCompany in availableGroup.availableCompanies"
                :key="availableCompany.compId"
                v-on:click="onCompanySelected(availableCompany)"
                :class="{'select-company-button': availableCompany !== selected.company, 'select-company-button-active': availableCompany === selected.company}"
                  block
                >
                  {{ availableCompany.compName }}
                </b-button>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right mt-3">
              <button @click="onConfirmSelection" :disabled="!selected.group || !selected.company"
                class="btn btn-primary mt-1">
                <i v-if="isLoading" class="ri-loader-line"></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'CompanyActiveSelection',
  components: {
  },
  computed: {
    availableGroups() {
      const groups = this.$store.getters['auth/availableGroup'];
      return groups.map((group) => {
        const companies = this.$store.getters['auth/availableCompany'];
        const mappedAvailable = {
          ...group,
          availableCompanies: Object.values(companies).filter((company) => company.compGrcpId === group.grcpId),
        };
        return mappedAvailable;
      });
    },
  },
  data() {
    return {
      isLoading: false,
      selected: {
        group: undefined,
        company: undefined,
      },
    };
  },
  methods: {
    async onConfirmSelection() {
      await this.$store.dispatch('auth/changeActiveCompany', this.selected);
      this.$router.push({ name: 'AppActiveSelection' });
    },
    onCompanySelected(company) {
      this.selected.company = company;
    },
    onGroupSelected(group) {
      this.selected.group = group;
    },
  },
};
</script>

<style scoped>
  .select-group-company-wrapper{
    width: 85%;
    margin: 0 auto;
    max-height: 400px;
    overflow: scroll;
  }
  .select-group-button {
    background-color: #E5398C;
    border-width: 0;
    font-weight: 500;
  }
  .select-company-button {
    border-color: #E5398C;
    border-width: 2;
    color:#E5398C;
    background-color: white;
    font-weight: 500;
  }
  .select-company-button-active {
    color: white;
    background-color: white;
    background-color: #E5398C;
    border-width: 0;
    font-weight: 500;
  }
</style>
