<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-2">
          <h4 class="card-title">Permission List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options
                :access="'master_role'"
                :hideAdd="false"
                :hideDetail="true"
                :isRefreshEnabled="true"
                :isRefreshed="isRefreshed"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <permission-list
              :access="'master_permission'"
              :data="data"
              :loading="isLoading"
              :pagination="pagination"
              @onActionDelete="openDelete"
              @onActionEdit="openEdit"
              @onActionView="openDetail"
              @onPageChanged="onPageChanged"
              @update="onUpdated"/>
          </b-col>
        </b-row>
      </div>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayForm">
        <permission-form
          :data="currentPermission"
          :errors="errors"
          :isSubmit="isSubmit"
          @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion">
        <delete-item
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"/>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import PermissionForm from '@/components/permission/Form';
import PermissionList from '@/components/permission/List';
import PermissionSvc from '@/services/PermissionSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'Permission',
  components: {
    DeleteItem,
    PermissionForm,
    PermissionList,
    ButtonOptions,
  },
  data() {
    return {
      currentPermission: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 50,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const resp = await PermissionSvc.deletePermission(data.prmsId);
        if (resp.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const permissionResponse = await PermissionSvc.getPermissions({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });
        this.data = permissionResponse.data.data;
        this.pagination.totalSize = permissionResponse.data.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentPermission = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      const data = {
        prmsName: form.prmsName,
        prmsOperations: JSON.stringify(form.prmsOperations),
      };

      this.errors = {};
      this.isSubmit = true;
      try {
        let resp = {};
        if (form.prmsId) {
          resp = await PermissionSvc.updatePermission(form.prmsId, data);
        } else {
          resp = await PermissionSvc.createPermission(data);
        }
        if (resp.data.status.error === 0) {
          await this.getData('Success');
          this.currentPermission = {};
          this.displayForm = false;
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error('Error When Submiting Data');
        console.error('error when submiting data', e);
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentPermission = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'RoleDetail', params: { id: data.prmsId } });
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentPermission = { ...this._.find(this.data, (v) => v.prmsId === data.prmsId) };
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>

</style>
