<template>
  <div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Email" label-for="inputTextEmail">
          <b-form-input id="inputTextEmail" type="text" placeholder="Enter email" v-model="form.email"
            :class="errors.email || $v.form.email.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.email">
            {{ errors.email }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="form-group">
      <button class="btn w-100 btn-block btn-primary" type="submit" @click="requestResetPassword" :disabled="isSubmit">
        <i v-if="isSubmit" class="ri-loader-line"></i>
        <i v-if="isError" class="ri-alert"></i>
        Request Reset Password
        <i class="ri-arrow-right-s-line"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import axios from 'axios';

export default {
  data() {
    return {
      error: null,
      errors: {},
      form: {
        email: undefined,
      },
      isSubmit: false,
      isError: true,
    };
  },
  mixins: [validationMixin],
  methods: {
    async requestResetPassword() {
      try {
        this.resetError();
        this.isSubmit = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          this.isSubmit = false;
        }

        const res = await axios.post('/account/reset_password/request', {
          email: this.form.email,
        });

        console.debug('res : ', res);

        this.isSubmit = false;
        this.$emit('onSuccess', 'Please check your email for further information.');
      } catch (err) {
        console.debug('res : ', err);
        this.isSubmit = false;
        this.$emit('onFailure', 'Failed to request reset password. please try again later or check your email address.');
      }
    },
    resetError() {
      this.errors = {};
    },
  },
  name: 'ForgotPasswordForm',
  validations: {
    form: {
      email: {
        required,
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.email': function () {
      this.$v.form.email.$touch();
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #c850af;
  border-color: #c850af;
}

.btn-primary:hover {
  background-color: #b71fa8;
  border-color: #b71fa8;
}
</style>
