import axios from 'axios';

/**
 * Service untuk mengelola Bunker Location
 * @namespace BunkerLocationSvc
 */
export default {
  /**
   * Mendapatkan semua data Bunker Location
   * @function getBunkerLocation
   * @return {Promise} Promise yang berisi response dari server
   */
  getBunkerLocation: async (params) => {
    const { data } = await axios.get('/master/bunker_location', {
      params: {
        ...params,
      },
    });
    return data;
  },
  /**
   * Mendapatkan data Bunker Location berdasarkan ID
   * @function getBunkerLocationById
   * @param {number} id - ID dari Bunker Location yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getBunkerLocationById: async (id) => {
    const { data } = await axios.get(`/master/bunker_location/${id}`);
    return data;
  },
  /**
   * Membuat data Bunker Location baru
   * @function createBunkerLocation
   * @param {object} data - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createBunkerLocation: async (bunkerLocationData) => {
    const { data } = await axios.post('/master/bunker_location', bunkerLocationData);
    return data;
  },
  /**
   * Mengupdate data Bunker Location yang sudah ada
   * @function updateBunkerLocation
   * @param {number} id - ID dari Bunker Location yang ingin di update
   * @param {object} data - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateBunkerLocation: async (id, bunkerLocationData) => {
    const { data } = await axios.put(`/master/bunker_location/${id}`, bunkerLocationData);
    return data;
  },
  /**
   * Menghapus data Bunker Location yang sudah ada
   * @function deleteBunkerLocation
   * @param {number} id - ID dari Bunker Location yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteBunkerLocation: async (id) => {
    const { data } = await axios.delete(`/master/bunker_location/${id}`);
    return data;
  },
};
