<template>
  <!-- <Map
    :center="data[0] ? data[0].centerMap : [-6.200000, 106.816666]"
    :data="data"
    :hidden="isLoading"
    style="position: sticky"/> -->
    <div>
      <h1>MAP</h1>
    </div>
</template>

<script>
// import Map from '@/components/directives/iq-map';

export default {
  name: 'analytics-data-map',
  props: ['centerMap', 'data', 'isLoading'],
  components: {
    // Map,
  },
};
</script>

<style scoped>

</style>
