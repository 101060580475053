import Vue from 'vue';
// import ToggleSwitch from 'vuejs-toggle-switch';
import Vuelidate from 'vuelidate';
import VueApexCharts from 'vue-apexcharts';
import VueBootstrap, { BootstrapVueIcons } from 'bootstrap-vue';
// import VueChart from 'vue-chartjs';
import VueLodash from 'vue-lodash';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueToast from 'vue-toast-notification';
// eslint-disable-next-line no-unused-vars

import axios from 'axios';
import lodash from 'lodash';
import vSelect from 'vue-select';

import AccessMixin from '@/components/auth/AccessMixin.vue';
import iqCard from '@/components/card/iq-card.vue';
import ApexChart from '@/components/widgets/charts/ApexChart.vue';
import VueSpeedometer from 'vue-speedometer';
import { Datetime } from 'vue-datetime';
import VCalendar from 'v-calendar';

import '@/filters/location';
import '@/filters/date.js';
import '@/filters/unit.js';
import '@/plugins/dayjs';

// import { OktaAuth } from '@okta/okta-auth-js';
// import OktaVue from '@okta/okta-vue';

import router from './router';
import store from './store';

import App from './App.vue';

import 'apexcharts/src/apexcharts.js';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/css/custom_template.css';
import 'vue-select/dist/vue-select.css';

import './assets/css/custom.css';
import './assets/css/remixicon.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'vue-datetime/dist/vue-datetime.css';

axios.defaults.baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/';
if (localStorage.mantraz_auth_token) {
  axios.defaults.headers.common.Authorization = `${localStorage.mantraz_auth_token}`;
}
// axios.defaults.baseURL = 'https://dev.local.ramus.id/api/';
// axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

Vue.use(VueBootstrap);
Vue.use(VueLodash, { lodash });
Vue.use(VueSidebarMenu);
Vue.use(VueToast);
Vue.use(Vuelidate);
Vue.use(BootstrapVueIcons);
Vue.use(Datetime);
Vue.component('datetime', Datetime);

Vue.use(VCalendar);

Vue.component('apexchart', VueApexCharts);
Vue.component('ApexChart', ApexChart);
Vue.component('v-select', vSelect);
Vue.component('iq-card', iqCard);
Vue.component('vue-speedometer', VueSpeedometer);

Vue.mixin(AccessMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
