import axios from 'axios';

export default {

  getPermissions(params) {
    return axios.get('/master/permission', { params });
  },
  getPermissionById(id, params) {
    return axios.get(`/master/permission/${id}`, { params });
  },
  createPermission(data) {
    return axios.post('/master/permission', data);
  },
  deletePermission(id) {
    return axios.delete(`/master/permission/${id}`);
  },
  updatePermission(id, data) {
    return axios.put(`/master/permission/${id}`, data);
  },
};
