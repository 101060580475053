<template>
  <div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="New Password" label-for="inputTextNewPassword">
          <b-form-input id="inputTextNewPassword" type="password" placeholder="Enter new password" v-model="form.new_password"
            :class="errors.new_password || $v.form.new_password.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.new_password">
            {{ errors.new_password }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.new_password.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Confirm New Password" label-for="inputTextConfirmNewPassword">
          <b-form-input id="inputTextConfirmNewPassword" type="password" placeholder="Enter confirm new password" v-model="form.confirm_new_password"
            :class="errors.confirm_new_password || $v.form.confirm_new_password.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.confirm_new_password">
            {{ errors.confirm_new_password }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.confirm_new_password.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="form-group">
      <button class="btn w-100 btn-block btn-primary" type="submit" @click="updatePassword" :disabled="isSubmit">
        <i v-if="isSubmit" class="ri-loader-line"></i>
        <i v-if="isError" class="ri-alert"></i>
        Update Password
        <i class="ri-arrow-right-s-line"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import axios from 'axios';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      errors: {},
      form: {
        email: this.email,
        new_password: undefined,
        confirm_new_password: undefined,
      },
      isSubmit: false,
      isError: false,
    };
  },
  validations: {
    form: {
      new_password: {
        required,
      },
      confirm_new_password: {
        required,
        sameAs: sameAs('new_password'),
      },
    },
  },
  methods: {
    async updatePassword() {
      try {
        this.resetError();
        this.isSubmit = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          this.isSubmit = false;
        }

        if (this.form.new_password !== this.form.confirm_new_password) {
          this.isSubmit = false;
          this.errors = {
            confirm_new_password: 'Password does not match',
          };
          return;
        }

        const { emailtoken, token } = this.$route.params;

        await axios.post('/account/reset_password/reset', {
          password: this.form.new_password,
          email: emailtoken,
          token,
        }, {
          allow_redirects: false,
        });

        this.isLoading = false;
        this.$emit('onSuccess');
      } catch (err) {
        this.isLoading = false;
        this.$emit('onFailure', 'Failed to update password');
      }
    },
    resetError() {
      this.errors = {};
    },
  },
  name: 'UpdatePasswordForm',
};
</script>

<style scoped>
.btn-primary {
  background-color: #c850af;
  border-color: #c850af;
}

.btn-primary:hover {
  background-color: #b71fa8;
  border-color: #b71fa8;
}
</style>
