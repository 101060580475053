<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" rowspan="2">Bunker Start</th>
          <th class="text-center" rowspan="2">Bunker End</th>
          <th class="text-center" rowspan="2">Asset</th>
          <th class="text-center" rowspan="2">Bunker Location</th>
          <th class="text-center" rowspan="2">Fuel Type</th>
          <th class="text-center" rowspan="2">Target Bunker</th>
          <th class="text-center" rowspan="2">Start Sounding</th>
          <th class="text-center" rowspan="2">End Sounding</th>
          <th class="text-center" colspan="2">Total Bunker</th>
          <th class="text-center" colspan="2">Discrepancy To Sounding</th>
          <th class="text-center" colspan="2">Discrepancy To Target Bunker</th>
          <th class="text-center" rowspan="2">Actions</th>
        </tr>
        <tr>
          <th class="text-center">Sounding</th>
          <th class="text-center">Mantraz</th>
          <th class="text-center">Total</th>
          <th class="text-center">%</th>
          <th class="text-center">Total</th>
          <th class="text-center">%</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="9" class="text-center text-danger">
            <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
          </td>
        </tr>
        <tr v-for="item in (canRead(access) ? BunkeringLogData : [])" :key="item.bklgId">
          <td class="cell-content">{{ item.bklgStartDatetime }}</td>
          <td class="cell-content">{{ item.bklgEndDatetime }}</td>
          <td class="cell-content">{{ item.massName }}</td>
          <td class="cell-content">{{ item.mbloName }}</td>
          <td class="cell-content">{{ item.mftyName }}</td>
          <td class="cell-content"><b>{{ item.bklgTargetBunker }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgStartSounding }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgEndSounding }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgTotalBunker }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.totalBunkerFM }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgDiscrepancyToSoundingTotal }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgDiscrepancyToSoundingPercentage }}%</b></td>
          <td class="cell-content"><b>{{ item.bklgDiscrepancyToFlowmeterTotal }}</b> {{ bunkerUnit.toUpperCase() }}</td>
          <td class="cell-content"><b>{{ item.bklgDiscrepancyToFlowmeterPercentage }}%</b></td>
          <td class="text-center cell-content">
            <bunker-bunkering-log-edit
              :access="'bunker_bunkering_log'"
              :dataToEdit="item.rawData"
              @onDeleteClicked="onActionDelete"
              @onViewClicked="onActionView"
              @onEditClicked="onActionEdit"/>
          </td>
        </tr>
        <tr v-if="!BunkeringLogData.length && !loading">
          <td colspan="9" class="text-center">No data available</td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      v-model="pagination.current"
      :per-page="pagination.pageSize"
      :total-rows="pagination.totalSize"
      @change="onPaginationChanged"
    ></b-pagination>
  </div>
</template>

<script>
import moment from 'moment';
import BunkerBunkeringLogEdit from './Edit';

export default {
  name: 'BunkerBunkeringLogList',
  components: { BunkerBunkeringLogEdit },
  props: ['access', 'data', 'group', 'loading', 'pagination', 'bunkerUnit'],
  data() {
    return {};
  },
  computed: {
    BunkeringLogData() {
      const bunkerUnit = this.bunkerUnit;
      const currentOffset = moment().utcOffset();

      const mappedBunkeringLogData = this.data.map((item) => ({
        bklgId: item?.bklgId,
        bklgStartDatetime: moment(item?.bklgStartDatetime).utc(true).add(currentOffset, 'minutes').format('DD MMMM YYYY HH:mm'),
        bklgEndDatetime: moment(item?.bklgEndDatetime).utc(true).add(currentOffset, 'minutes').format('DD MMMM YYYY HH:mm'),
        massName: item?.bklgMass?.massName,
        mbloName: item?.bklgMblo?.mbloName,
        mftyName: item?.bklgMfty?.mftyName,
        bklgTargetBunker: (bunkerUnit === 'kl' ? parseFloat(item?.bklgTargetBunker) * 0.001 : parseFloat(item?.bklgTargetBunker)).toFixed(2),
        bklgStartSounding: (bunkerUnit === 'kl' ? parseFloat(item?.bklgStartSounding) * 0.001 : parseFloat(item?.bklgStartSounding)).toFixed(2),
        bklgEndSounding: (bunkerUnit === 'kl' ? parseFloat(item?.bklgEndSounding) * 0.001 : parseFloat(item?.bklgEndSounding)).toFixed(2),
        bklgTotalBunker: (bunkerUnit === 'kl' ? parseFloat(item?.bklgTotalBunker) * 0.001 : parseFloat(item?.bklgTotalBunker)).toFixed(2),
        totalBunkerFM: (bunkerUnit === 'kl' ? (parseFloat(item?.bklgDiscrepancyToFlowmeter.total) + parseFloat(item?.bklgTargetBunker)) * 0.001 : (parseFloat(item?.bklgDiscrepancyToFlowmeter.total) + parseFloat(item?.bklgTargetBunker))).toFixed(2),
        bklgDiscrepancyToSoundingTotal: (bunkerUnit === 'kl' ? parseFloat(item?.bklgDiscrepancyToSounding.total) * 0.001 : parseFloat(item?.bklgDiscrepancyToSounding.total)).toFixed(2),
        bklgDiscrepancyToSoundingPercentage: item?.bklgDiscrepancyToSounding.percentage,
        bklgDiscrepancyToFlowmeterTotal: (bunkerUnit === 'kl' ? parseFloat(item?.bklgDiscrepancyToFlowmeter.total) * 0.001 : parseFloat(item?.bklgDiscrepancyToFlowmeter.total)).toFixed(2),
        bklgDiscrepancyToFlowmeterPercentage: item?.bklgDiscrepancyToFlowmeter.percentage,
        rawData: item,
      }));

      return mappedBunkeringLogData;
    },
  },
  methods: {
    onPaginationChanged(page) {
      this.$emit('onPageChanged', page);
    },
    onActionDelete(data) {
      this.$emit('onActionDelete', data);
    },
    onActionEdit(data) {
      this.$emit('onActionEdit', data);
    },
    onActionView(data) {
      this.$emit('onActionView', data);
    },
  },
};
</script>

<style scoped>
.cell-content {
  white-space: nowrap; /* Mencegah teks membungkus ke baris baru */
  overflow: hidden; /* Sembunyikan konten yang melampaui */
  text-overflow: ellipsis; /* Tampilkan elipsis (...) untuk konten yang terpotong */
}

.table th,
.table td {
  min-width: 200px; /* Atur lebar minimum kolom */
  max-width: 300px; /* Atur lebar maksimum kolom */
}
</style>
