<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Asset Type</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_asset_type'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <asset-type-list :access="'master_asset_type'" :data="data" :loading="isLoading" :pagination="pagination"
              @onActionDelete="openDelete" @onActionEdit="openEdit" @onActionView="openDetail"
              @onPageChanged="onPageChanged" @update="onUpdated" />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <asset-type-form :data="currentAssetType" :errors="errors" :isSubmit="isSubmit" @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import AssetTypeList from '@/components/asset_type/List';
import AssetTypeForm from '@/components/asset_type/Form';
import AssetTypeSvc from '@/services/AssetTypeSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'User',
  components: {
    DeleteItem, AssetTypeForm, AssetTypeList, ButtonOptions,
  },
  data() {
    return {
      currentAssetType: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteAssetTypeResponse = await AssetTypeSvc.deleteAssetType(data.matyId);
        if (deleteAssetTypeResponse.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteAssetTypeResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;
        const assetTypeResponse = await AssetTypeSvc.getAssetType({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });

        console.debug('assetTypeResponse : ', assetTypeResponse);

        const assetTypeData = assetTypeResponse.data;

        this.data = assetTypeData;

        this.pagination.totalSize = assetTypeResponse.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something Wrong');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentAssetType = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Asset Type Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      try {
        this.errors = {};
        this.isSubmit = true;

        console.debug('form : ', form);

        const assetTypeData = {
          matyName: form.matyName,
        };

        if (!form.matyId) { // jika tidak ada matyId maka create
          const createAssetTypeResponse = await AssetTypeSvc.createAssetType(assetTypeData);
          console.debug('createAssetTypeResponse : ', createAssetTypeResponse);

          if (createAssetTypeResponse.status.error === 0) {
            await this.getData('Success');
            this.currentAssetType = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada matyId maka update
          const updateAssetTypeResponse = await AssetTypeSvc.updateAssetType(form.matyId, assetTypeData);
          console.debug('updateAssetTypeResponse : ', updateAssetTypeResponse);

          if (updateAssetTypeResponse.status.error === 0) {
            await this.getData('Success');
            this.currentAssetType = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error When Submiting Form');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentAssetType = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentAssetType = { ...this._.find(this.data, (v) => v.matyId === data.matyId) };
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>
