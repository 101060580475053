<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Fuel Type</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_fuel_type'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <fuel-type-list :access="'master_fuel_type'" :data="data" :loading="isLoading" :pagination="pagination"
              @onActionDelete="openDelete" @onActionEdit="openEdit" @onActionView="openDetail"
              @onPageChanged="onPageChanged" @update="onUpdated" />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <fuel-type-form :data="currentFuelType" :errors="errors" :isSubmit="isSubmit" @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import FuelTypeSvc from '@/services/FuelTypeSvc';
import FuelTypeList from '@/components/fuel_type/List';
import FuelTypeForm from '@/components/fuel_type/Form';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'MasterFuelType',
  components: {
    DeleteItem, FuelTypeForm, FuelTypeList, ButtonOptions,
  },
  data() {
    return {
      currentFuelType: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteFuelTypeResponse = await FuelTypeSvc.deleteFuelType(data.mftyId);
        if (deleteFuelTypeResponse.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteFuelTypeResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;
        const fuelTypeResponse = await FuelTypeSvc.getFuelType({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });

        const fuelTypeData = fuelTypeResponse.data;

        this.data = fuelTypeData;

        this.pagination.totalSize = fuelTypeResponse.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something Wrong');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentFuelType = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Fuel Type Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      try {
        this.errors = {};
        this.isSubmit = true;

        const fuelTypeData = {
          mftyName: form.mftyName,
        };

        if (!form.mftyId) { // jika tidak ada mftyId maka create
          const createFuelTypeResponse = await FuelTypeSvc.createFuelType(fuelTypeData);

          if (createFuelTypeResponse.status.error === 0) {
            await this.getData('Success');
            this.currentFuelType = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada mftyId maka update
          const updateFuelTypeResponse = await FuelTypeSvc.updateFuelType(form.mftyId, fuelTypeData);
          console.debug('updateFuelTypeResponse : ', updateFuelTypeResponse);

          if (updateFuelTypeResponse.status.error === 0) {
            await this.getData('Success');
            this.currentFuelType = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error When Submiting Form');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentFuelType = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentFuelType = { ...this._.find(this.data, (v) => v.mftyId === data.mftyId) };
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
